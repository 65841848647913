<template lang="pug">
  .slot-table__item.slot-table__item_wrap
    .slot-table__cell.slot-table__cell_time {{ item.timeHour }}
    .slot-table__cell.slot-table__cell_quant
      b {{ item.quantity }}
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
